import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { Link } from "react-router";
import PlaceholderText from "Components/PlaceholderText";

import Screenshot from "Components/Screenshot";

const ProjectCardLayout = styled.div`
  max-width: 100%;
  margin: 0 0 32px;
  cursor: pointer;
  border-radius: 4px;
  width: 366px;
  a {
    text-decoration: none;
  }
  .card-top {
    display: flex;
    flex-direction: column;
    padding: 24px 32px 0;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #fff;
    width: 100%;
    position: relative;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    .organization {
      font-size: 14px;
      margin-bottom: 16px;
      color: #98a0ab;
    }
    .title {
      margin: 0 0 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
      line-height: 24px;
      color: #0e0d34;
    }
    .screenshot {
      margin: 0 auto;
      height: 170px;
      overflow: hidden;
      background: #fff;
      box-shadow: 0 8px 40px rgba(14, 13, 52, 0.3);
      @media (min-width: 1800px) {
        max-width: 368px;
      }
    }
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }
  .card-bottom {
    padding: 0 32px;
    height: 64px;
    line-height: 64px;
    background: #fff;
    color: #38485e;
    font-size: 17px;
    border-top: none;
    border-radius: 0 0 4px 4px;
    .type-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .type {
      height: 64px;
      line-height: 64px;
      display: flex;
      align-items: center;
      float: left;
      span.region-label:first-letter {
        text-transform: uppercase;
      }
    }
    .type-icon {
      height: 18px;
      width: 18px;
      text-align: center;
      line-height: 18px;
      display: inline-block;
    }
    .type-label {
      font-weight: 500;
      color: #000;
      margin: 0 10px 0 0;
    }
    .updated {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  &.project-list-item {
    width: 100%;
    margin: 0;
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: no-wrap;
      align-items: center;
      justify-content: space-between;
      color: #5f5e70;
    }
  }
  &:hover {
    box-shadow: 0 6px 44px rgba(152, 160, 171, 0.6);
  }
  @media (min-width: 768px) {
    .card-top .screenshot {
      height: 180px;
      &.no-screenshot {
        img {
          margin-top: -16px;
        }
      }
    }
  }
  @media (min-width: 834px) and (max-width: 1024px) {
    .card-top .screenshot {
      height: 170px;
      &.no-screenshot {
        img {
          margin-top: 0;
        }
      }
    }
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
    .card-top .screenshot {
      height: 164px;
      .no-screenshot {
        img {
          margin-top: 0;
        }
      }
    }
  }
`;

const ProjectCard = ({ children, id, gridLayout, project, url }) => {
  return (
    <ProjectCardLayout
      className={gridLayout ? "project-card col-4" : "project-list-item"}
    >
      <Link
        id={`project-card-${id}`}
        to={url}
        tabIndex="0"
        href={url ? url : ""}
      >
        {gridLayout ? (
          <div>
            <div className="card-top">
              <div className="heading">
                <h2 className="title">
                  {project.title ? (
                    project.title
                  ) : (
                    <PlaceholderText text="loading" />
                  )}
                </h2>
                <div className="organization">
                  {project.owner_info.username}
                </div>
              </div>
              <div className="screenshot-wrapper">
                <Screenshot imagePath={`/${id}/master/screenshot.png`} />
              </div>
            </div>
            <div className="card-bottom">
              <div className="type-wrapper">
                <div className="type">
                  {project &&
                    (project.region_label ? (
                      <span className="region-label">
                        {project.region_label}
                      </span>
                    ) : (
                      <span className="region">{project.region}</span>
                    ))}
                </div>
              </div>
              {children}
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="cell cell-project">
              {project.title ? (
                project.title
              ) : (
                <PlaceholderText text="loading" />
              )}
            </div>
            <div className="cell cell-organization col-3">
              {project.owner_info.username}
            </div>
            <div className="cell cell-region col-3">{project.region_label}</div>
            <div className="cell cell-region col-2">{project.id}</div>
          </div>
        )}
      </Link>
    </ProjectCardLayout>
  );
};

ProjectCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  gridLayout: PropTypes.bool,
  project: PropTypes.object,
  url: PropTypes.string
};

export default injectIntl(ProjectCard);
