import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";
import styled, { withTheme } from "styled-components";

import Loading from "Components/Loading";
import ProjectCard from "Components/ProjectCard";
import ProjectsListLayout from "Components/ProjectsListLayout";
import {
  getOrganizationDescriptionIdFromProject,
  sortProjectList
} from "Libs/utils";
import ErrorBoundary from "Components/ErrorBoundary";
import Label from "Components/fields/Label";
import ChevronIcon from "Icons/ChevronIcon";
import Heading2 from "Components/styleguide/Heading2";
import ButtonLink from "Components/ButtonLink";
import EmptyProjects from "Components/illustrations/EmptyProjects";

const LabelWrapper = styled.div`
  display: flex;
  margin: -8px -32px 0;
  padding: 0 32px;
  border-bottom: 1px solid #c9e0e4;
  .label {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: #5f5e70;
    span.chevron {
      min-width: 16px;
      margin-top: 2px;
    }
    &.descending span.chevron {
      margin-top: 0;
      margin-bottom: 2px;
    }
    @media (max-width: 1024px) {
      min-width: 24%;
    }
  }
  .label-project {
    width: 100%;
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const EmptyProjectsListLayout = styled.div`
  text-align: center;
  margin-top: 130px;
  width: 100%;
  h2 {
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 40px;
    color: #5f5e70;
    font-size: 15px;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 auto 24px;
  width: 156px;
  svg {
    max-width: 100%;
    height: auto;
  }
`;

const Projects = ({
  organizationId,
  isLoading,
  projects,
  search,
  gridLayout,
  intl,
  theme
}) => {
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("ascend");

  const projectFilter = project => {
    const title = project.title;
    const id = project.id;

    if (!search) {
      return true;
    }

    if (id.toUpperCase().includes(search.toUpperCase())) {
      return true;
    }

    if (!title) {
      return false;
    }

    return title.toUpperCase().includes(search.toUpperCase());
  };

  const toggleSort = value => {
    let newSortOrder = sortOrder;
    let newSortType = sortType;
    if (sortType === value) {
      if (sortOrder === "ascend") {
        newSortOrder = "descend";
      } else {
        newSortOrder = "ascend";
      }
    } else {
      newSortType = value;
      newSortOrder = "ascend";
    }

    setSortType(newSortType);
    setSortOrder(newSortOrder);
  };

  if (isLoading) {
    return <Loading iconOnly={true} />;
  }

  if (!projects) {
    return false;
  }

  return (
    <ErrorBoundary>
      {projects.size === 0 ? (
        <EmptyProjectsListLayout>
          <ImageWrapper>
            <EmptyProjects />
          </ImageWrapper>
          <Heading2>No projects</Heading2>
          <p>Create your first project to begin using Platform.sh</p>
          <ButtonLink
            className="add-project"
            addLink={true}
            to={`/${
              organizationId ? organizationId : "projects"
            }/create-project`}
            text={intl.formatMessage({ id: "add_project" })}
          />
        </EmptyProjectsListLayout>
      ) : (
        <ProjectsListLayout className={gridLayout ? "grid" : "list"}>
          <LiveMessage message="list of projects" aria-live="polite" />
          {!gridLayout && (
            <LabelWrapper>
              <Label
                className={`label label-project${
                  sortType === "name" && sortOrder === "descend"
                    ? " descending"
                    : ""
                }`}
                onClick={() => toggleSort("name")}
              >
                Project name{" "}
                <ChevronIcon
                  color={sortType === "name" ? theme.links : "#4b6180"}
                  isOpen={sortType === "name" && sortOrder === "descend"}
                  animate={true}
                />
              </Label>
              <Label
                className={`label label-owner col-3${
                  sortType === "owner" && sortOrder === "descend"
                    ? " descending"
                    : ""
                }`}
                onClick={() => toggleSort("owner")}
              >
                Owner{" "}
                <ChevronIcon
                  color={sortType === "owner" ? theme.links : "#4b6180"}
                  isOpen={sortType === "owner" && sortOrder === "descend"}
                  animate={true}
                />
              </Label>
              <Label
                className={`label label-region col-3${
                  sortType === "region" && sortOrder === "descend"
                    ? " descending"
                    : ""
                }`}
                onClick={() => toggleSort("region")}
              >
                Region{" "}
                <ChevronIcon
                  color={sortType === "region" ? theme.links : "#4b6180"}
                  isOpen={sortType === "region" && sortOrder === "descend"}
                  animate={true}
                />
              </Label>
              <Label
                className={`label label-id col-2${
                  sortType === "id" && sortOrder === "descend"
                    ? " descending"
                    : ""
                }`}
                onClick={() => toggleSort("id")}
              >
                ID{" "}
                <ChevronIcon
                  color={sortType === "id" ? theme.links : "#4b6180"}
                  isOpen={sortType === "id" && sortOrder === "descend"}
                  animate={true}
                />
              </Label>
            </LabelWrapper>
          )}
          {projects
            .valueSeq()
            .filter(projectFilter)
            .sort((a, b) => sortProjectList(a, b, { sortOrder, sortType }))
            .map(project => (
              <ProjectCard
                key={project.id}
                title={project.title || intl.formatMessage({ id: "no_title" })}
                id={project.id}
                gridLayout={gridLayout}
                project={project}
                url={`/${getOrganizationDescriptionIdFromProject(project)}/${
                  project.id
                }`}
              />
            ))}
          {projects.toArray().filter(projectFilter).length === 0 && (
            <EmptyProjectsListLayout>
              <ImageWrapper>
                <EmptyProjects />
              </ImageWrapper>
              <Heading2>Sorry, no projects found</Heading2>
              <p>Try adjusting your search</p>
            </EmptyProjectsListLayout>
          )}
        </ProjectsListLayout>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state, props) => ({
  projects: state.project.getIn(
    ["data", props.organizationId],
    state.project
      .getIn(["data"], new Map())
      .keySeq()
      .reduce((projects, key) => {
        state.project.getIn(["data", key], new Map()).forEach(project => {
          projects = projects.mergeDeep(projects.set(project.id, project));
        });
        return projects;
      }, new Map())
  ),
  isLoading: state.project.get("loading"),
  gridLayout:
    props.gridLayout /*,
  organizations: state.organization.get("data", new Map())*/
});

const mapDispatchToProps = dispatch => ({
  loadProjects: () => {
    import("Reducers/project").then(projectReducer =>
      dispatch(projectReducer.loadProjects())
    );
  },
  push: (path, args) => {
    import("Reducers/app").then(appReducer =>
      dispatch(appReducer.push(path, args))
    );
  }
});

Projects.propTypes = {
  children: PropTypes.node,
  loadProjects: PropTypes.func,
  push: PropTypes.func.isRequired,
  organizationId: PropTypes.string,
  isLoading: PropTypes.bool,
  // organizations: PropTypes.object,
  projects: ImmutablePropTypes.map,
  search: PropTypes.string,
  gridLayout: PropTypes.bool,
  intl: PropTypes.object,
  theme: PropTypes.object
};

Projects.defaultProps = {
  search: ""
};

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(Projects))
);
