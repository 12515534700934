import React from "react";
import PropTypes from "prop-types";

const ListIcon = ({ color = "#5f5e70" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 22"
    height="24"
    width="24"
  >
    <defs>
      <clipPath id="link-icon-a">
        <path
          d="M5,5V7H19V5Zm14,7V10H5v2ZM5,17H19V15H5Z"
          style={{ fill: "none", clipRule: "evenodd" }}
        />
      </clipPath>
      <clipPath id="link-icon-b">
        <rect width="24" height="22" style={{ fill: "none" }} />
      </clipPath>
      <clipPath id="link-icon-c">
        <rect x="5" y="5" width="14" height="12" style={{ fill: "none" }} />
      </clipPath>
    </defs>
    <title>Asset 1</title>
    <g style={{ clipPath: "url(#link-icon-a)" }}>
      <g style={{ clipPath: "url(#link-icon-b)" }}>
        <g style={{ clipPath: "url(#link-icon-c)" }}>
          <rect width="24" height="22" style={{ fill: color }} />
        </g>
      </g>
    </g>
  </svg>
);

ListIcon.propTypes = {
  color: PropTypes.string
};

export default ListIcon;
