import React from "react";

const EmptyProjects = () => (
  <svg
    width="156"
    height="117"
    viewBox="0 0 156 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77 116.5C111.242 116.5 139 112.023 139 106.5C139 100.977 111.242 96.5 77 96.5C42.7583 96.5 15 100.977 15 106.5C15 112.023 42.7583 116.5 77 116.5Z"
      fill="#C9D0E4"
    />
    <path
      opacity="0.3"
      d="M114.335 35.5564H79.8099L76.319 27.3617C76.029 26.8314 75.4738 26.5 74.869 26.5H53.6572C52.7457 26.5 52 27.2457 52 28.1572V35.5564V35.8547V43.1132C52 44.0246 52.7457 44.7703 53.6572 44.7703H114.343C115.254 44.7703 116 44.0246 116 43.1132V37.2136C115.992 36.3022 115.246 35.5564 114.335 35.5564Z"
      fill="#5792FF"
    />
    <rect x="45" y="69.5" width="64" height="17" rx="2" fill="#F0F2F5" />
    <path
      d="M72.8099 39.6314L69.319 31.3689C69.029 30.8342 68.4738 30.5 67.869 30.5H46.6572C45.7457 30.5 45 31.2519 45 32.1709V39.6314V39.9322V84.8291C45 85.7481 45.7457 86.5 46.6572 86.5H107.343C108.254 86.5 109 85.7481 109 84.8291V41.3023C109 40.3833 108.254 39.6314 107.343 39.6314H72.8099Z"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M45 49.5C69.9936 49.5 109 49.5 109 49.5"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M9.1875 66.2875L10.5875 67.6875L8.4875 69.7875C8.1375 70.1375 8.1375 70.6625 8.4875 71.0125L8.575 71.1C8.925 71.45 9.45 71.45 9.8 71.1L11.9 69L13.3 70.4C13.5625 70.6625 14.0875 70.4875 14.0875 70.05V65.9375C14.0875 65.675 13.9125 65.5 13.65 65.5H9.5375C9.1 65.5 8.925 65.9375 9.1875 66.2875ZM4.2 65.5H0.4375C0.175 65.5 0 65.675 0 65.9375V69.7C0 70.05 0.4375 70.3125 0.7875 70.05L2.0125 68.825L5.6 72.4125C5.95 72.7625 6.125 73.2 6.125 73.6375V78.625C6.125 79.15 6.475 79.5 7 79.5C7.525 79.5 7.875 79.15 7.875 78.625V72.85C7.875 72.4125 7.7 71.975 7.35 71.625L3.2375 67.5125L4.4625 66.2875C4.8125 65.9375 4.55 65.5 4.2 65.5Z"
      fill="#C9D0E4"
    />
    <path
      d="M113.1 1V13"
      stroke="#C9D0E4"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M107.1 7.27002C107.648 7.27002 119.1 7.27002 119.1 7.27002"
      stroke="#C9D0E4"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.5 10.5V18.5"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.5 14.7C18.86 14.7 26.5 14.7 26.5 14.7"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M153.829 62.5L151 65.3284"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M145.928 56.5L143 67.4282"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M151 59.6716C151.127 59.7988 153.828 62.5 153.828 62.5"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M135 62.5L137.828 59.6716"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M137.829 65.3284C137.701 65.2012 135 62.5 135 62.5"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <rect
      x="114"
      y="90.9142"
      width="2"
      height="9.37646"
      rx="1"
      transform="rotate(-45 114 90.9142)"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <circle
      cx="104"
      cy="78.5"
      r="16"
      fill="#F0F2F5"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <circle
      cx="104"
      cy="78.5"
      r="13.5"
      stroke="#5792FF"
      strokeOpacity="0.3"
      strokeWidth="3"
    />
    <path
      d="M101 83.39C101.654 82.2602 102.875 81.5 104.274 81.5C105.673 81.5 106.895 82.2602 107.549 83.39"
      stroke="#4B6180"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <circle cx="108.5" cy="75.5" r="1.5" fill="#4B6180" />
    <circle cx="99.5" cy="75.5" r="1.5" fill="#4B6180" />
  </svg>
);

export default EmptyProjects;
