import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding-bottom: 50px;
  &.grid {
    display: flex;
    justify-content: center;
    &:after {
      content: "";
      width: 432px;
    }
    @media (min-width: 768px) {
      .project-card {
        width: 432px;
      }
    }
    @media (max-width: 833px) {
      flex-direction: column;
      align-items: center;
    }
    @media (min-width: 834px) {
      .project-card {
        width: 362px;
        margin-right: 32px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
    @media (min-width: 976px) {
      .project-card:nth-child(2n) {
        margin-right: 0;
      }
    }
    @media (min-width: 1024px) {
      .project-card {
        width: 432px;
      }
    }
    @media (min-width: 1280px) {
      justify-content: flex-start;
      .project-card {
        width: 380px;
      }
      .project-card:nth-child(2n) {
        margin-right: 32px;
      }
      .project-card:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  &.list {
    display: block;
    background: #fff;
    border-radius: 3px;
    margin-bottom: 50px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    padding: 32px;
    margin-bottom: 50px;
    .project-list-item .row,
    .table-header {
      width: 100%;
      display: flex;
      flex-wrap: no-wrap;
      justify-content: space-between;
      align-items: center;
      box-shadow: none;
    }
    .project-list-item {
      border-bottom: 1px solid #c9e0e4;
      color: #38485e;
      box-shadow: none;
      &:last-child {
        border-bottom: none;
      }
    }
    .cell {
      line-height: 56px;
      font-size: 13px;
      font-weight: 600;
      @media (max-width: 1024px) {
        min-width: 24%;
      }
    }
    .cell-project {
      color: #38485e;
      width: 100%;
      font-size: 15px;
    }
    .cell-region,
    .cell-activity,
    .cell-status {
      width: 120px;
    }
    .cell-organization,
    .cell-plan {
      text-transform: capitalize;
    }
    .status-notice {
      position: relative;
      display: inline-block;
      top: auto;
      left: auto;
      right: auto;
      border: none;
      height: 20px;
      width: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .stack {
      margin-bottom: 0;
    }
  }
`;
