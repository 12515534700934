import React from "react";
import PropTypes from "prop-types";

const PushIcon = ({ color = "#5f5e70" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    height="24"
    width="24"
  >
    <defs>
      <clipPath id="icon-grid-a">
        <path
          d="M12.2,5V9.8H17V5Zm0,12H17V12.2H12.2ZM5,5H9.8V9.8H5ZM5,17V12.2H9.8V17Z"
          style={{ fill: "none", clipRule: "evenodd" }}
        />
      </clipPath>
      <clipPath id="icon-grid-b">
        <rect width="22" height="22" style={{ fill: "none" }} />
      </clipPath>
      <clipPath id="icon-grid-c">
        <rect x="5" y="5" width="12" height="12" style={{ fill: "none" }} />
      </clipPath>
    </defs>
    <title>Asset 2</title>
    <g style={{ clipPath: "url(#icon-grid-a)" }}>
      <g style={{ isolation: "isolate" }}>
        <g style={{ clipPath: "url(#icon-grid-b)" }}>
          <g style={{ clipPath: "url(#icon-grid-c)" }}>
            <rect width="22" height="22" style={{ fill: color }} />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

PushIcon.propTypes = {
  color: PropTypes.string
};

export default PushIcon;
