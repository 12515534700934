import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const PlaceholderContainer = styled.div`
  color: transparent;
  text-shadow: rgba(255, 255, 255, 0.95) 0px 0px 20px;
`;

const PlaceholderText = ({ text }) => {
  return <PlaceholderContainer>{text}</PlaceholderContainer>;
};

PlaceholderText.propTypes = {
  text: PropTypes.string
};

export default PlaceholderText;
