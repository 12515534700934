import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import ListIcon from "Icons/ListIcon";
import GridIcon from "Icons/GridIcon";
import ToggleWrapper from "Components/ToggleWrapper";

const ToggleLayout = ({ toggleLayout, intl, gridLayout }) => {
  return (
    <ToggleWrapper className="layout-toggle">
      <span
        role="button"
        tabIndex="0"
        onClick={() => {
          toggleLayout(true);
          document.activeElement.blur();
        }}
        onKeyUp={e => e.keyCode === 13 && toggleLayout(true)}
        aria-label={intl.formatMessage({
          id: "projects.grid_layout"
        })}
        className={gridLayout ? "active" : ""}
      >
        <GridIcon />
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => {
          toggleLayout(false);
          document.activeElement.blur();
        }}
        onKeyUp={e => e.keyCode === 13 && toggleLayout(false)}
        aria-label={intl.formatMessage({
          id: "projects.list_layout"
        })}
        className={gridLayout ? "" : "active"}
      >
        <ListIcon />
      </span>
    </ToggleWrapper>
  );
};

ToggleLayout.propTypes = {
  toggleLayout: PropTypes.func,
  gridLayout: PropTypes.bool,
  intl: PropTypes.object
};

export default injectIntl(ToggleLayout);
