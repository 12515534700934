import React, { useState } from "react";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";

import Projects from "Containers/Projects";
import ContentLayout from "Components/ContentLayout";
import ProjectFilterBar from "Components/ProjectFilterBar";

const MyProjects = ({ router, params, search }) => {
  const [gridLayout, toggleLayout] = useState(true);

  return (
    <ContentLayout>
      <ProjectFilterBar
        toggleLayout={toggleLayout}
        gridLayout={gridLayout}
        organization={params.organizationId}
      />
      {gridLayout ? (
        <LiveMessage
          message="project list displayed as a grid"
          aria-live="polite"
        />
      ) : (
        <LiveMessage
          message="project list displayed as a list"
          aria-live="polite"
        />
      )}
      <Projects
        organizationId={params.organizationId}
        search={search}
        router={router}
        gridLayout={gridLayout}
      />
    </ContentLayout>
  );
};

MyProjects.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    organizationId: PropTypes.string
  }),
  setSubNavBarTitleAndTabs: PropTypes.func,
  toggleLayout: PropTypes.func,
  gridLayout: PropTypes.bool,
  search: PropTypes.string
};

export default MyProjects;
