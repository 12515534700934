import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import ToggleLayout from "Components/ToggleLayout";
import Heading2 from "Components/styleguide/Heading2";

const ProjectFilterBarLayout = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 12px 0;
  box-sizing: border-box;
  h2 {
    width: 100%;
    margin: 0;
  }
  .layout-toggle {
    box-shadow: none;
    span {
      padding: 4px;
    }
  }
  @media (max-width: 360px) {
    .search-wrapper {
      margin-top: 20px;
    }
  }
  @media (min-width: 480px) {
    .add-link {
      min-width: 102px;
    }
  }
  @media (min-width: 600px) {
    .filter_selector {
      margin: 0;
    }
  }
`;

const ProjectFilterBar = ({ toggleLayout, gridLayout }) => {
  return (
    <ProjectFilterBarLayout className="project-filter-bar">
      <Heading2>All Projects</Heading2>
      <ToggleLayout toggleLayout={toggleLayout} gridLayout={gridLayout} />
    </ProjectFilterBarLayout>
  );
};

ProjectFilterBar.propTypes = {
  intl: PropTypes.object,
  toggleLayout: PropTypes.func,
  gridLayout: PropTypes.bool,
  organizationId: PropTypes.string
};

export default injectIntl(ProjectFilterBar);
